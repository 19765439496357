import APIService from './APIService';

export default {
  getById(bdgdId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/versoes-bdgd/get-by-id/${bdgdId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getUltimaDataBase(empresaId) {
    return APIService.apiCall().get(
      `/versoes-bdgd/get-ultima-data-base/${empresaId}`
    );
  },

  getResumoBdgdPorTuc(bdgdId) {
    return APIService.apiCall().get(
      `/versoes-bdgd/get-resumo-por-tuc/${bdgdId}`
    );
  },

  getBdgdVersion(companyId, month) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/versoes-bdgd/get-maior-versao/${companyId}/${month}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getBdgdVersions(companyId, month) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/versoes-bdgd/get-versoes/${companyId}/${month}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getAllBdgdVersionsByCompanyId(companyId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/versoes-bdgd/get-by-company-id/${companyId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDatesAndRegistersImportedEntitiesByCompanyId(empresaId) {
    return APIService.apiCall().get(
      `/versoes-bdgd/get-all-versoes/${empresaId}`
    );
  },

  deletar(id) {
    return APIService.apiCall().get(`/versoes-bdgd/deletar/${id}`);
  },

  reverseOrdinaryBdgdVersion(id) {
    return APIService.apiCall().patch(
      `/versoes-bdgd/reverse-ordinary-bdgd-version/${id}`
    );
  },

  setColor(id, color) {
    return APIService.apiCall().patch(
      `/versoes-bdgd/set-color/${id}`,
      JSON.stringify({ color })
    );
  },

  setTitle(id, title) {
    return APIService.apiCall().patch(
      `/versoes-bdgd/set-title/${id}`,
      JSON.stringify({ title })
    );
  },

  async versaoModulo10AntigoExists(companyId, data, version) {
    return !!(await APIService.apiCall().get(
      `/versoes-bdgd/versao-modulo10-antigo-exists/${companyId}/${data}/${version}`
    )).data;
  }
};
