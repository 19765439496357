<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="importacao-titulo"
      class="mx-auto"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="fs-titulo-importacao-dados"
            label="Título da importação"
            outlined
            :error-messages="errors"
            v-model="titulo"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      id="importacao-descricao"
      class="mx-auto"
    >
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-importacao-dados"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-empresa"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-company
          id="fs-empresa-importacao-dados"
          :type="'outlined'"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-tipo"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-tipos-importacoes-dados
          id="fs-tipo-importacao-dados"
          :type="'outlined'"
          :companyId="companyId"
          @tipoImportacaoSelecionado="tipoSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-mes"
      class="mx-auto"
      v-if="dataRegistroObrigatorio"
    >
      <v-col
        cols="12"
        id="fs-mes-dados-importacao-dados"
      >
        <input-month
          label="Mês dos dados"
          @monthSelected="monthSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-bdgd-versao"
      class="mx-auto"
      v-if="bdgdVersaoObrigatoria && companyId"
    >
      <v-col cols="12">
        <select-all-bdgd-versions
          type="outlined"
          :showIcon="false"
          :companyId="companyId"
          @bdgdVersionSelected="bdgdVersionSelected"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectCompany from '@/components/general/SelectCompany.vue';
import SelectTiposImportacoesDados from '@/components/general/SelectTiposImportacoesDados.vue';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions.vue';
import InputMonth from '@/components/general/InputMonth.vue';

export default {
  name: 'FirstTabContent',
  components: {
    SelectCompany,
    SelectTiposImportacoesDados,
    SelectAllBdgdVersions,
    InputMonth
  },
  data() {
    return {
      titulo: null,
      descricao: null,
      companyId: null,
      tipoImportacao: null,
      mes: null,
      bdgdVersaoId: null
    };
  },
  computed: {
    data() {
      return this.mes ? `${this.mes}-01` : null;
    },
    dataRegistroObrigatorio() {
      return (
        this.tipoImportacao && this.tipoImportacao.data_registro_obrigatorio
      );
    },
    bdgdVersaoObrigatoria() {
      return this.tipoImportacao && this.tipoImportacao.bdgd_versao_obrigatoria;
    }
  },
  watch: {
    titulo() {
      this.emitEventImportUpdated();
    },
    descricao() {
      this.emitEventImportUpdated();
    },
    companyId() {
      this.emitEventImportUpdated();
    },
    tipoImportacao() {
      this.emitEventImportUpdated();
    },
    mes() {
      this.emitEventImportUpdated();
    },
    bdgdVersaoId() {
      this.emitEventImportUpdated();
    }
  },
  methods: {
    tipoSelected(tipoImportacao) {
      this.tipoImportacao = tipoImportacao;
    },
    companySelected(company) {
      this.companyId = company.id;
    },
    monthSelected(month) {
      this.mes = month;
    },
    bdgdVersionSelected(bdgdVersion) {
      if (bdgdVersion) {
        this.bdgdVersaoId = bdgdVersion.id;
      } else {
        this.bdgdVersaoId = null;
      }
    },
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        titulo: this.titulo,
        descricao: this.descricao,
        companyId: this.companyId,
        tipoImportacao: this.tipoImportacao,
        mes: this.mes,
        data: this.data,
        bdgdVersaoId: this.bdgdVersaoId
      });
    }
  }
};
</script>
