<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
  >
    <v-select
      no-gutters
      v-model="bdgdVersionId"
      item-value="id"
      item-text="versaoTxt"
      :prepend-icon="showIcon ? 'mdi-numeric-1-box-multiple-outline' : null"
      :outlined="type === 'outlined'"
      :items="bdgdVersions"
      :label="label"
      :error-messages="errors"
    />
  </validation-provider>
</template>

<script>
import BdgdVersionsService from '@/services/BdgdVersionsService';
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  name: 'SelectAllBdgdVersions',

  mixins: [dateFormatterMixins],

  props: {
    type: {
      type: String,
      default: 'solo'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Versão da BDGD'
    },
    companyId: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      bdgdVersionId: null,
      bdgdVersions: []
    };
  },

  mounted() {
    this.getBdgdVersions();
  },

  computed: {
    bdgdVersion() {
      if (this.bdgdVersionId) {
        return this.bdgdVersions.find(
          (bdgdVersion) => bdgdVersion.id === this.bdgdVersionId
        );
      }
      return null;
    },
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    },
    rules() {
      let rules = '';
      if (this.required) {
        rules += 'required';
      }
      return rules;
    }
  },

  watch: {
    companyId() {
      this.getBdgdVersions();
    },

    data() {
      if (this.bdgdVersion) return this.bdgdVersion.mes;
      return null;
    },

    bdgdVersions() {
      if (this.userSelectedCompanyBdgdVersion) {
        this.bdgdVersionId = this.userSelectedCompanyBdgdVersion.id;
      } else {
        this.getLastBdgdVersion();
      }
    },

    bdgdVersion() {
      this.$emit('bdgdVersionSelected', this.bdgdVersion);
    }
  },

  methods: {
    getBdgdVersions() {
      if (!this.companyId) {
        return;
      }

      BdgdVersionsService.getAllBdgdVersionsByCompanyId(this.companyId)
        .then((response) => {
          this.bdgdVersions = response.map((bdgdVersion) => {
            const mesBr = this.formatMonth(bdgdVersion.data_registro);
            bdgdVersion.versaoTxt = `${mesBr} v${bdgdVersion.versao}`;
            bdgdVersion.mes = this.formatDateToPicker(
              bdgdVersion.data_registro
            );

            return bdgdVersion;
          });
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar as versões da BDGD.', '', {
            position: 'topRight'
          });
        });
    },

    getLastBdgdVersion() {
      if (!(this.bdgdVersions && this.bdgdVersions.length)) return;

      BdgdVersionsService.getBdgdVersion(
        this.companyId,
        this.bdgdVersions[0].mes
      )
        .then((lastBdgdVersion) => {
          if (lastBdgdVersion) {
            let bdgdVersion = this.bdgdVersions
              .filter((version) => version.versao === lastBdgdVersion)
              .shift();
            if (bdgdVersion && bdgdVersion.id) {
              this.bdgdVersionId = bdgdVersion.id;
            }
          }
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao busca a última versão da BDGD.', '', {
            position: 'topRight'
          });
        });
    }
  }
};
</script>
