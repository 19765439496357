<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="100"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="loading"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        v-slot="{ errors }"
        :rules="rules"
      >
        <v-text-field
          :outlined="outlined"
          v-model="formatedMonth"
          :error-messages="errors"
          v-mask="'##/####'"
          :label="label"
          prepend-inner-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          @blur="month = parseMonth(formatedMonth)"
          :clearable="!loading"
          :loading="loading"
          :disabled="loading"
        />
      </validation-provider>
    </template>
    <v-date-picker
      :disabled="loading"
      type="month"
      v-model="month"
      @input="
        menu = false;
        formatedMonth = formatMonth(month);
      "
      :landscape="false"
      locale="pt-br"
    />
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'InputMonth',
  props: {
    label: {
      type: String,
      default: 'Mês'
    },
    selectedMonth: {
      type: String,
      default: null
    },
    rules: {
      type: Object,
      default() {
        return {
          required: true,
          min: 7
        };
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      month: null,
      formatedMonth: null,
      menu: false
    };
  },
  mounted() {
    if (this.selectedMonth) {
      this.month = this.selectedMonth;
      this.formatedMonth = this.formatMonth(this.selectedMonth);
    }
  },
  watch: {
    month() {
      this.$emit('monthSelected', this.month);
    },
    selectedMonth() {
      if (this.selectedMonth) {
        this.month = this.selectedMonth;
        this.formatedMonth = this.formatMonth(this.selectedMonth);
      } else {
        this.month = null;
        this.formatedMonth = null;
      }
    }
  },
  methods: {
    parseMonth(date) {
      if (!date) return null;
      const [month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}`;
    },
    formatMonth(date) {
      if (date.length > 10) {
        date = date.substr(0, 10);
      }
      return dayjs(date).format('MM/YYYY');
    }
  }
};
</script>
