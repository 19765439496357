<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Nova Importação de Dados"
        subtitle="Escolha o tipo de importação e faça o upload do arquivo a ser importado."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content @import:updated="handleTabContentUpdated" />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            @encoding:selected="handleEncodingSelected"
            @file:uploaded="handleFileUploaded"
            @deleteData:changed="handleDeleteDataChanged"
            :dataImportType="importacao.tipoImportacao"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import ImportacoesDadosService from '@/services/ImportacoesDadosService';
import MaterialWizard from '@/components/base/MaterialWizard.vue';
import FirstTabContent from '@/components/dados/importacoes-dados/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/dados/importacoes-dados/tabs/SecondTabContent.vue';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],
  components: {
    MaterialWizard,
    FirstTabContent,
    SecondTabContent
  },
  data: () => ({
    tab: 0,
    tabs: ['Informações básicas', 'Upload de arquivo'],
    loading: false,
    importacao: {
      titulo: null,
      descricao: null,
      companyId: null,
      bdgdVersaoId: null,
      data: null,
      mes: null,
      tipoImportacao: null,
      arquivo: null,
      encoding: 'UTF-8',
      excluirDadosAntesImportacao: false
    },
    arrTourPrimeiraTab: [
      {
        element: '#fs-titulo-importacao-dados',
        intro: 'Aqui você deverá inserir um título para sua importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-descricao-importacao-dados',
        intro: 'Aqui você poderá inserir uma descrição para sua importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-empresa-importacao-dados',
        intro:
          'Aqui você deverá selecionar a empresa alvo que receberá a importação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#fs-tipo-importacao-dados',
        intro:
          'Aqui você deverá informar o tipo de importação que será feita (AIS, Laudo, etc).',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#fs-upload-importacao-dados',
        intro:
          'Nesta seção deverá ser enviado o arquivo com os dados a serem importados.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ]
  }),
  mounted() {
    this.iniciarTourPrimeiraTab();
  },
  computed: {
    availableSteps() {
      let steps = [];
      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }
      if (this.tab2IsValid) {
        steps.push(2);
      }
      return steps;
    },
    tab1IsValid() {
      const { titulo, companyId, tipoImportacao, mes, bdgdVersaoId } =
        this.importacao;
      return (
        titulo !== null &&
        companyId !== null &&
        tipoImportacao !== null &&
        tipoImportacao.id &&
        !(tipoImportacao.data_registro_obrigatorio && mes === null) &&
        !(tipoImportacao.bdgd_versao_obrigatoria && bdgdVersaoId === null)
      );
    },
    tab2IsValid() {
      return this.tab1IsValid && this.importacao.arquivo !== null;
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.cadastraNovaImportacao();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: routes.state.appRoutes['IMPORTACOES_DE_DADOS']
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.importacao = Object.assign(this.importacao, event);
    },
    handleFileUploaded(file) {
      this.importacao.arquivo = file;
    },
    handleEncodingSelected(event) {
      this.importacao.encoding = event;
    },
    handleDeleteDataChanged(event) {
      this.importacao.excluirDadosAntesImportacao = event;
    },
    async cadastraNovaImportacao() {
      let sucesso = false;
      this.loading = true;

      try {
        const {
          titulo,
          descricao,
          data,
          arquivo,
          encoding,
          tipoImportacao,
          companyId,
          bdgdVersaoId,
          excluirDadosAntesImportacao
        } = this.importacao;

        const importacao = {
          titulo,
          descricao,
          data_registro: data,
          arquivo_id: arquivo.id,
          encoding,
          tipo_importacao_id: tipoImportacao.id,
          company_id: companyId,
          bdgd_versao_id: bdgdVersaoId,
          excluir_dados_antes_importacao: excluirDadosAntesImportacao
        };
        sucesso = await ImportacoesDadosService.save(importacao);
        this.$toast.success('Importação cadastrada com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error('Ocorreu um erro ao cadastrar a importação.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    },
    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;
      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.initTourSegundaTab();
        }
      };
      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },
    initTourSegundaTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;
      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };
      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    }
  }
};
</script>
