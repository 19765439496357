<template>
  <div>
    <a
      href="javascript:void(0)"
      class="text-decoration-none"
      @click="baixarArquivoModelo()"
      >Clique aqui</a
    >
    para baixar o modelo para a importação
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
  </div>
</template>

<script>
import ImportacoesDadosService from '@/services/ImportacoesDadosService';

export default {
  name: 'LinkDownloadArquivoModelo',
  props: {
    tipoImportacao: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    baixarArquivoModelo() {
      this.loading = true;

      ImportacoesDadosService.baixarArquivoModelo(this.tipoImportacao.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            this.tipoImportacao.arquivo_modelo_importacao
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo modelo.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
