import APIService from './APIService';

export default {
  getImportacoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('importacoes/dados')
        .then((response) => {
          const { data: importacoes } = response.data;
          resolve(importacoes);
        })
        .catch((error) => reject(error));
    });
  },
  save(data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('importacoes/dados/salvar', JSON.stringify(data))
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  },
  getAllowedFiles() {
    return APIService.apiCall().get('importacoes/dados/arquivos-permitidos');
  },
  getLogsImportacao(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`importacoes/dados/logs/${id}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  },
  baixarLogs(id) {
    return APIService.apiCall().get(`importacoes/dados/logs-importacao/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarLogsTecnicos(id) {
    return APIService.apiCall().get(`importacoes/dados/logs-tecnicos/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarArquivo(id) {
    return APIService.apiCall().get(`arquivos/download/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  },
  baixarArquivoModelo(tipoImportacaoId) {
    return APIService.apiCall().get(
      `importacoes/dados/download/arquivo-modelo/${tipoImportacaoId}`,
      {
        responseType: 'blob',
        ...{
          timeout: 60 * 60 * 1000
        }
      }
    );
  }
};
