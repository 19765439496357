<template>
  <v-select
    v-model="company"
    name="company"
    label="Empresa"
    :items="orderedCompanies"
    item-value="id"
    item-text="name"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhuma empresa disponível"
    :loading="loading"
    @change="
      changeCompany === true ? companySelected($event) : handleSelect($event)
    "
  />
</template>

<script>
import CompanyService from '@/services/CompanyService';

export default {
  name: 'SelectCompany',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    changeCompany: {
      type: Boolean,
      default: true
    },
    allCompanies: {
      type: Boolean,
      default: false
    },
    selectedCompany: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      company: '',
      companies: [],
      loading: true
    };
  },
  created() {
    this.allCompanies ? this.getCompanies() : this.getCompaniesByLoggedUser();
  },
  methods: {
    getCompanies() {
      CompanyService.getCompanies()
        .then((companies) => this.setCompanies(companies))
        .finally(() => (this.loading = false));
    },
    getCompaniesByLoggedUser() {
      CompanyService.getCompaniesByLoggedUser()
        .then((companies) => this.setCompanies(companies))
        .finally(() => (this.loading = false));
    },
    setCompanies(companies) {
      this.companies = companies;
      if (this.selectedCompany) {
        this.company = companies.find(
          (company) => company.id === this.selectedCompany.id
        );
        this.companySelected(this.company.id);
      }
    },
    companySelected(event) {
      const companySelected = this.companies.find(
        (company) => company.id === event
      );
      this.$emit('companySelected', companySelected);
    },
    handleSelect(companySelected) {
      this.$emit('selected', companySelected);
    }
  },
  computed: {
    orderedCompanies() {
      let companies = [...this.companies];
      return companies.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
  }
};
</script>
