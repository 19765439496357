import APIService from './APIService';

export default {
  getCompanies() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('companies')
        .then((response) => {
          const { data: companies } = response.data;
          resolve(companies);
        })
        .catch((error) => reject(error));
    });
  },

  getCompaniesByLoggedUser() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/companies/companies-by-logged-user')
        .then((response) => {
          const { data: companies } = response.data;
          resolve(companies);
        })
        .catch((error) => reject(error));
    });
  },

  getAll() {
    return APIService.apiCall().get('/companies/get-all');
  },

  getCompanyById(id) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/companies/get-by-id/${id}`)
        .then((response) => {
          const { data: companies } = response.data;
          resolve(companies);
        })
        .catch((error) => reject(error));
    });
  },

  getSchemas(id) {
    return APIService.apiCall().get(`/companies/get-schemas/${id}`);
  },

  changeStatus(id) {
    return APIService.apiCall().post(`/companies/change-status/${id}`);
  },

  save(postData) {
    return APIService.apiCall().post(
      '/companies/save',
      JSON.stringify(postData)
    );
  },

  getNetworkExtentsByCompanyId(id, data) {
    return APIService.apiCall().get(
        `/companies/get-network-extents-by-company-id/${id}`, {
          params: data
        }
    );
  },

  getNetworkExtentById(id) {
    return APIService.apiCall().get(
        `/companies/get-network-extent-by-id/${id}`,
    );
  },

  changeNetworkExtentStatus(id) {
    return APIService.apiCall().post(
        `/companies/change-network-extent-status/${id}`,
    );
  },

  saveNetworkExtent(id, postData) {
    return APIService.apiCall().post(
        `/companies/save-network-extent/${id}`,
        JSON.stringify(postData)
    );
  },

  saveGeographicExtent(id, postData) {
    return APIService.apiCall().post(
      `/companies/save-geographic-extent/${id}`,
      JSON.stringify(postData)
    );
  },

  updateMandatoryBdgdEntities(id, postData) {
    return APIService.apiCall()
      .post(
        `/companies/update-mandatory-bdgd-entities/${id}`,
        JSON.stringify(postData)
      )
      .then((response) => {
        console.log(response);
      });
  }
};
