<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie o arquivo de dados a ser importado
    </div>
    <v-row
      id="importacao-encoding"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-encoding
          id="fs-encoding-importacao-dados"
          label="Codificação do arquivo"
          type="outlined"
          :selected="encoding"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-upload-files"
      class="mx-auto"
    >
      <v-col cols="12">
        <upload-files
          id="fs-upload-importacao-dados"
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        >
          <template v-slot:upload-alert-extra-information>
            <link-download-arquivo-modelo
              id="fs-link-download-importacao-dados"
              :tipoImportacao="dataImportType"
            />
          </template>
        </upload-files>
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="lastUploadedFile"
    >
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="lastUploadedFile"
    >
      <v-col cols="12">
        <v-checkbox
          v-model="deleteData"
          label="Apagar os dados antes de importar?"
          color="error"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImportacoesDadosService from '@/services/ImportacoesDadosService';
import SelectEncoding from '@/components/general/SelectEncoding.vue';
import UploadFiles from '@/components/general/UploadFiles/Index.vue';
import LinkDownloadArquivoModelo from '@/components/dados/importacoes-dados/LinkDownloadArquivoModelo.vue';

export default {
  name: 'SecondTabContent',
  components: {
    SelectEncoding,
    UploadFiles,
    LinkDownloadArquivoModelo
  },
  props: {
    dataImportType: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      encoding: 'UTF-8',
      allowedFiles: [],
      uploadedFiles: [],
      deleteData: false
    };
  },
  mounted() {
    this.getAllowedFiles();
    this.encoding = this.dataImportType.encoding;
  },
  watch: {
    encoding() {
      this.$emit('encoding:selected', this.encoding);
    },
    uploadedFiles() {
      this.$emit('file:uploaded', this.lastUploadedFile);
    },
    deleteData() {
      this.$emit('deleteData:changed', this.deleteData);
    },
    dataImportType() {
      this.encoding = this.dataImportType.encoding;
    }
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  methods: {
    getAllowedFiles() {
      ImportacoesDadosService.getAllowedFiles()
        .then((response) => (this.allowedFiles = response.data))
        .catch((error) => console.log('Error: ' + error));
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    encodingSelected(event) {
      this.encoding = event;
    }
  }
};
</script>
